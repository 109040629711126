<template>
  <div class="news newsCont">
    <div class="banner">
      <div class="title">引领行业发展，深度挖掘场景价值需求</div>
    </div>
    <div class="newslist">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#fff"
        text-color="#000"
        active-text-color="#f76151"
        router
      >
        <el-menu-item index="/news?categoryId=1">全部新闻</el-menu-item>
        <el-menu-item index="/news?categoryId=13">公司新闻</el-menu-item>
        <el-menu-item index="/news?categoryId=11">产品新闻</el-menu-item>
        <el-menu-item index="/news?categoryId=12">行业信息</el-menu-item>
      </el-menu>
      <div class="content contentBox">
        <router-view></router-view>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "@/components/footer/footer.vue";
export default {
  name: "news",
  components: { Foot },
  computed: {
    activeIndex() {
      if (!this.$route.query.categoryId) {
        console.log(
          "this.$route.query.categoryId",
          this.$route.query.categoryId
        );
        this.$router.push({
          path: "/news",
          query: {
            categoryId: 1,
          },
        });
        return "/news?categoryId=1";
      }
      return `/news?categoryId=${this.$route.query.categoryId}`;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
